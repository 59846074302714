import {
  createListCollection,
  SelectContent,
  SelectItem,
  SelectLabel,
  SelectRoot,
  SelectTrigger,
  SelectValueChangeDetails,
  SelectValueText,
} from '@mr-yum/frontend-ui'
import { useLogger } from 'hooks/useLogger'
import { observer } from 'mobx-react-lite'
import React, { useContext, useMemo } from 'react'
import { MenuStoreContext } from 'stores/MenuStore'

import { languageItems, ValidLanguageFilters } from './utils'

const collection = createListCollection({ items: languageItems })

export const LanguagePicker = observer(function LanguagePicker() {
  const { logEvent } = useLogger()

  const { language, setLanguage } = useContext(MenuStoreContext)

  const value = useMemo(() => [language], [language])

  const handleClick = () => {
    logEvent('Clicks on language picker')
  }

  const handleChange = (details: SelectValueChangeDetails) => {
    const newLanguage = details.value[0] as ValidLanguageFilters
    setLanguage(newLanguage)

    logEvent('Language changed', {
      nextLanguage: newLanguage,
      previousLanguage: language,
    })
  }

  return (
    <SelectRoot
      className="w-40"
      value={value}
      collection={collection}
      onClick={handleClick}
      onValueChange={handleChange}
    >
      <SelectLabel className="hidden">Select language</SelectLabel>
      <SelectTrigger>
        <SelectValueText />
      </SelectTrigger>
      <SelectContent portalled={false}>
        {collection.items.map((item, index) => (
          <SelectItem key={index} item={item}>
            {item.label}
          </SelectItem>
        ))}
      </SelectContent>
    </SelectRoot>
  )
})
